import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ContainerCard from './ContainerCard';  // Nouvel import

const TemplateCard = ({ template, onDeploy, isDeploying }) => (
  <div className="border rounded-lg p-4 shadow-sm">
    <h3 className="font-medium">{template.label}</h3>
    <p className="text-gray-600 text-sm mb-4">{template.name}</p>
    <button
      onClick={() => onDeploy(template.id)}
      disabled={isDeploying}
      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed"
    >
      {isDeploying ? 'Deploying...' : 'Deploy'}
    </button>
  </div>
);

const LoadingSpinner = () => (
  <div className="flex justify-center items-center py-8">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
  </div>
);

const Dashboard = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [containers, setContainers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deployingTemplates, setDeployingTemplates] = useState(new Set());

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchContainers, 10000);
    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    try {
      await Promise.all([
        fetchTemplates(),
        fetchContainers()
      ]);
    } finally {
      setLoading(false);
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await fetch('/api/templates');
      if (!response.ok) throw new Error('Failed to fetch templates');
      const data = await response.json();
      setTemplates(data);
    } catch (err) {
      setError(err.message);
      console.error('Failed to fetch templates:', err);
    }
  };

  const fetchContainers = async () => {
    try {
      const response = await fetch('/api/containers');
      if (!response.ok) throw new Error('Failed to fetch containers');
      const data = await response.json();
      console.log('Containers data:', data); // Debug log
      setContainers(data);
    } catch (err) {
      console.error('Failed to fetch containers:', err);
    }
  };

  const handleDeploy = async (templateId) => {
    if (deployingTemplates.has(templateId)) return;
    
    setDeployingTemplates(prev => new Set([...prev, templateId]));
    setError(null);

    try {
      const response = await fetch(`/api/deploy?template_id=${templateId}`, {
        method: 'POST',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to deploy container');
      }

      await fetchContainers();
    } catch (err) {
      setError(err.message);
    } finally {
      setDeployingTemplates(prev => {
        const newSet = new Set(prev);
        newSet.delete(templateId);
        return newSet;
      });
    }
  };

  const handleRemoveContainer = async (containerId) => {
    try {
      const response = await fetch(`/api/containers/${containerId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to remove container');
      }

      await fetchContainers();
    } catch (err) {
      setError(err.message);
      console.error('Failed to remove container:', err);
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    navigate('/login');
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Docker Manager Dashboard</h1>
        <button
          onClick={handleLogout}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
        >
          Logout
        </button>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div className="space-y-8">
        <section>
          <h2 className="text-xl font-semibold mb-4">Available Templates</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {templates.map((template) => (
              <TemplateCard
                key={template.id}
                template={template}
                onDeploy={handleDeploy}
                isDeploying={deployingTemplates.has(template.id)}
              />
            ))}
          </div>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">Active Containers</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {containers.length === 0 ? (
              <p className="text-gray-500 col-span-full">No active containers</p>
            ) : (
              containers.map((container) => (
                <ContainerCard
                  key={container.id}
                  container={container}
                  onRemove={handleRemoveContainer}
                />
              ))
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
