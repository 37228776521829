import React, { useState } from 'react';

const ContainerCard = ({ container, onRemove }) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [showEnvironment, setShowEnvironment] = useState(false);
  
  const statusColors = {
    RUNNING: 'text-green-600',
    STOPPED: 'text-red-600',
    ERROR: 'text-orange-600'
  };

  const handleRemove = async (e) => {
    e.preventDefault();
    if (!window.confirm('Are you sure you want to remove this container?')) {
      return;
    }
    setIsRemoving(true);
    try {
      await onRemove(container.id);
    } finally {
      setIsRemoving(false);
    }
  };

  return (
    <div className="border rounded-lg p-4 shadow-sm space-y-3">
      {/* En-tête avec nom et statut */}
      <div className="flex items-center justify-between">
        <h3 className="font-medium text-lg">{container.name}</h3>
        <span className={`text-sm ${statusColors[container.status]}`}>
          Status: {container.status}
        </span>
      </div>

      <p className="text-sm text-gray-600">Base Port: {container.base_port}</p>

      {/* Liste des services */}
      <div className="space-y-4">
        {container.services && container.services.map(service => (
          <div key={service.service_name} className="bg-gray-50 p-3 rounded border">
            {/* Nom du service et image */}
            <div className="flex justify-between items-start mb-2">
              <div>
                <p className="font-medium">{service.container_name}</p>
                <p className="text-sm text-gray-500">{service.image}</p>
              </div>
            </div>

            {/* Ports et URLs */}
            {service.ports && service.ports.length > 0 && (
              <div className="mb-3">
                <p className="text-sm font-medium text-gray-700 mb-2">Access URLs:</p>
                <div className="flex flex-wrap gap-2">
                  {service.ports.map((port, idx) => (
                    <a
                      key={idx}
                      href={`http://${window.location.hostname}:${port.external}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-sm bg-blue-50 text-blue-600 hover:text-blue-800 px-2 py-1 rounded"
                    >
                      {port.external}:{port.internal}
                      <svg
                        className="w-4 h-4 ml-1"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                        />
                      </svg>
                    </a>
                  ))}
                </div>
              </div>
            )}

            {/* Variables d'environnement */}
            {service.environment && Object.keys(service.environment).length > 0 && (
              <div>
                <button
                  onClick={() => setShowEnvironment(!showEnvironment)}
                  className="text-sm text-gray-600 hover:text-gray-800 flex items-center mb-2"
                >
                  <svg
                    className={`w-4 h-4 mr-1 transition-transform ${showEnvironment ? 'rotate-90' : ''}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                  Environment Variables
                </button>
                {showEnvironment && (
                  <div className="bg-white p-3 rounded border text-sm space-y-1">
                    {Object.entries(service.environment).map(([key, value]) => (
                      <div key={key} className="grid grid-cols-2 gap-2">
                        <span className="font-medium text-gray-700">{key}:</span>
                        <span className="text-gray-600 break-all">{value}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Bouton de suppression */}
      <div className="flex justify-end pt-2 border-t">
        <button
          onClick={handleRemove}
          disabled={isRemoving}
          className="px-4 py-2 text-sm text-white bg-red-500 hover:bg-red-600 disabled:bg-red-300 rounded"
        >
          {isRemoving ? 'Removing...' : 'Remove Container'}
        </button>
      </div>
    </div>
  );
};

export default ContainerCard;
